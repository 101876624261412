import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from '@lodash';

export const getStatusesTypes = createAsyncThunk(
  'settingsApp/configurations/getStatusesTypes',
  async (routeParams, { dispatch, getState }) => {
    routeParams = routeParams || getState().settingsApp.configurations.routeParams;
    const copyRouteParams = {...routeParams}
     delete routeParams?.mode 
    const response = await axios.get('/api/statusesTypes', {
      params: routeParams,
    });
    const data = await response.data.data;
    return { data, routeParams, copyRouteParams };
  }
);

export const getStatusesType = createAsyncThunk(
  'settingsApp/configurations/getStatusesType',
  async ({id, category}, { dispatch, getState }) => {
    const response = await axios.get(`/api/statusesTypes/${id}`, {params: {category}});
    const data = await response.data.statusType;
    if (data?.custom_layout?.images) {
      data.custom_layout.images = data?.custom_layout?.images.map(item => {
        delete item.data
        return item
      })
    }
    delete data?.custom_layout?.stoneUrl?.data
    return data;
  }
);

export const addStatusesType = createAsyncThunk(
  'settingsApp/configurations/addStatusesType',
  async (inputData, { dispatch, getState }) => {
    const response = await axios.post('/api/statusesTypes', inputData);
    const data = await response.data;
    return data;
  }
);

export const updateStatusesType = createAsyncThunk(
  'settingsApp/configurations/updateStatusesType',
  async (inputData, { dispatch, getState }) => {
    if (inputData?.attachment?.data)
      delete inputData.attachment.data
    const response = await axios.put(`/api/statusesTypes/${inputData.id}`, inputData);
    const data = await response.data;
    return data;
  }
);

export const getUniqueStatusTypeCategories = createAsyncThunk(
  'settingsApp/configurations/getUniqueStatusTypeCategories',
  async () => {
    const response = await axios.get('/api/uniqueCategories');
    const data = await response.data;
    return { data };
  }
);

export const getGroupNames = createAsyncThunk(
  'settingsApp/configurations/getGroupNames',
  async (category) => {
    const response = await axios.get(`/api/groupNames/${category}`);
    const data = await response.data;
    return { category, data };
  }
);

export const getCategoryValues = createAsyncThunk(
  'settingsApp/configurations/getCategoryValues',
  async ({ params }) => {
    const response = await axios.get('/api/categoryValues', { params });
    const data = await response.data;
    return { data, params };
  }
);

const configurationsAdapter = createEntityAdapter({});

export const { selectAll: selectConfigurations, selectById: selectConfigurationsById } =
  configurationsAdapter.getSelectors((state) => state.settingsApp.configurations);

const configurationsSlice = createSlice({
  name: 'settingsApp/configurations',
  initialState: configurationsAdapter.getInitialState({
    searchText: '',
    category: null,
    productType: null,
    routeParams: {},
    categoryNames: [],
    productTypes: [],
    groupNames: {},
    configurationDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    setConfigurationsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    openNewConfigurationDialog: (state, action) => {
      state.configurationDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewConfigurationDialog: (state, action) => {
      state.configurationDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditConfigurationDialog: (state, action) => {
      const {data = {}, productTypeId = null} = action.payload || {};
      state.configurationDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data,
        productTypeId
      };
    },
    closeEditConfigurationDialog: (state, action) => {
      state.configurationDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
    setCategory: (state, action) => {
      state.category = action.payload || null;
    },
    setProductType: (state, action) => {
      state.productType = action.payload || null;
    },
    setProductTypes:(state, action)=>{
      configurationsAdapter.setAll(state, action.payload || []);
    }
  },
  extraReducers: {
    [getStatusesTypes.fulfilled]: (state, action) => {
      const { data, routeParams, copyRouteParams } = action.payload;
      if (!copyRouteParams?.mode) {
        configurationsAdapter.setAll(state, data);
        state.routeParams = routeParams;
        state.searchText = '';
      } else {
        configurationsAdapter.setAll(state, []);
        const filterItems = _.uniqBy(data, 'name');

        state.productTypes = filterItems;
      }
    },
    [getStatusesType.fulfilled]: (state, action) => {
      const { data } = action.payload;
    },
    [updateStatusesType.fulfilled]: configurationsAdapter.upsertOne,
    [addStatusesType.fulfilled]: configurationsAdapter.addOne,
    [getUniqueStatusTypeCategories.fulfilled]: (state, action) => {
      state.categoryNames = action.payload.data;
    },
    [getGroupNames.fulfilled]: (state, action) => {
      state.groupNames[action.payload.category] = action.payload.data;
    },
  },
});

export const {
  setConfigurationsSearchText,
  openNewConfigurationDialog,
  closeNewConfigurationDialog,
  openEditConfigurationDialog,
  closeEditConfigurationDialog,
  setCategory,
  setProductTypes,
  setProductType
} = configurationsSlice.actions;

export default configurationsSlice.reducer;
