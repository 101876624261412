import Icon from '@mui/material/Icon';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import AuraResponseDialog from 'app/main/apps/common/AuraImportResponse';
import { getProducts, uploadProducts } from '../e-commerce/store/productsSlice';
import { getOrders, uploadOrders } from '../e-commerce/store/ordersSlice';

function AuraImport(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate()

  const id = `button-file${props.id}`;
  function handleChange(e) {
    handlePopoverClose()
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    props.handleBackdropToggle(true);
    if (props.name === 'order' || props.name === 'quote') {
      dispatch(uploadOrders({ file, type: props.name, ...props.params }))
        .unwrap()
        .then((response) => {
          setResponseData(response);
          setOpen(true);
          e.target.value = null;
          if (!response.is_error)
            dispatch(getOrders({ params: { stage: props.name } }))
        })
        .finally(() => props.handleBackdropToggle(false));
    } else if (props.name === 'products') {
      dispatch(uploadProducts({ file, ...props.params }))
        .unwrap()
        .then((response) => {
          setResponseData(response.items);
          setOpen(true);
          e.target.value = null;
          if (!response.is_error)
            dispatch(getProducts({})).then(() => {
              props.handleBackdropToggle(false);
            });
        });
    }

    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = () => {
      if (props.onChange) {
        props.onChange(`data:${file.type};base64,${btoa(reader.result)}`);
      }
    };

    reader.onerror = () => {
      console.log('error on load file');
    };
  }

  const handleClose = () => {
    setOpen(false);
    setResponseData(null);
  };

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget); // Show popover when icon is clicked
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleScreenRedirect = () => {
    handlePopoverClose();
    navigate(`/orders/single-imports`)
  };

  const openPopover = Boolean(anchorEl);

  return (
    <>
      <input accept=".xlsx" className="hidden" id={id} type="file" onChange={handleChange} />
      <Tooltip title={props.title}>
        <IconButton className="w-32 h-32 mx-4 p-0" component="span" size="large" onClick={handleIconClick} >
          <Icon fontSize="small">{props.icon || 'save_alt'}</Icon>
        </IconButton>
      </Tooltip>
      <AuraResponseDialog
        data={responseData}
        open={open}
        onClose={handleClose}
        name={props.name}
      />
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          <ListItem button component="label" htmlFor={id}>
            <ListItemText primary="Import Single Orders" />
          </ListItem>
          <ListItem button onClick={handleScreenRedirect}>
            <ListItemText primary="View Imports" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

export default AuraImport;
