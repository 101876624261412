const locale = {
  APPLICATIONS: 'Applications',
  DASHBOARDS: 'Dashboards',
  ECOMMERCE: 'E-Commerce',
  ACADEMY: 'Academy',
  MAIL: 'Mail',
  TASKS: 'Tasks',
  FILE_MANAGER: 'File Manager',
  CONTACTS: 'Contacts',
  CHAT: 'Chat',
  SCRUMBOARD: 'Scrumboard',
  NOTES: 'Notes',
  DASHBOARD: 'Dashboard',
  PRODUCTS: 'Products',
  ENQUIRIES: 'Enquiries',
  QUOTES: 'Quotes',
  RECIEVED_ORDERS: 'Received Orders',
  CUSTOMERS: 'Customers',
  SUPPLIERS: 'Suppliers',
  In_PRODUCTION: 'In Production',
  INBOUNDSHIPMENTS: 'Inbound Shipments',
  CONTAINERSHIPMENTS: 'Container Shipments',
  OUTBOUNDSHIPMENTS: 'Outbound Shipments',
  NEWSHIPMENT: 'New Shipment',
  CALENDAR: 'Calendar',
  SHIPMENTPROFILES: 'Shipment Profiles',
  SETTINGS: 'Settings',
  EMPLOYEES: 'Employees',
  CONFIGURATIONS: 'Configurations',
  MAPPINGS: 'Mappings',
  TEMPLATES: 'Email Templates',
  PROFILES: 'Company Profiles',
  QUOTES_FOR_REVIEW: 'Quotes for Review',
  ORDER_FROM_INVENTORY: 'Order from Inventory',
  WAITING_FOR_APPROVAL: 'Waiting for Approval',
  SHIPPED_ORDER: 'Shipped Orders',
  CONFIRMED_ORDERS: 'Confirmed Orders',
  ORDERS: 'Orders',
  Supplier_Invoices: 'Supplier Invoice'
};

export default locale;
