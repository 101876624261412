import _ from '@lodash';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getOrders,
  getOrderStatuses,
  selectOrders,
  setCustomer,
  setSupplier,
  setOrdersSearchText,
} from 'app/main/apps/e-commerce/store/ordersSlice';
import { useDispatch, useSelector } from 'react-redux';
import FuseUtils from '@fuse/utils/FuseUtils';
import Confirm from '@fuse/core/FuseDialog/confirm';
import CustomerFilter, { OrderTypeFilter, OrderTyepFilterFunction, IncludeInvoiceFilter } from 'app/main/apps/e-commerce/shared/CustomerFilter';
import Entities from 'app/main/apps/e-commerce/shared/Entities';
import getColumns from 'app/main/apps/e-commerce/orders/OrdersTableColumns';
import { selectUser } from 'app/store/userSlice';
import { authPermissions } from 'app/auth';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { upperCase } from 'lodash';
import OrderDialog from '../order/Dialogs/OrderDialog';
import ViewOrderDialog from '../../../dashboards/main/dialogs/ViewOrderDialog';
import ReallocationDialog from '../order/Dialogs/ReallocationDialog';
import { isCustomer, isDefaultSupplierTypes, isSupplier } from '../../common/AuraFunctions';
import downloadFile from '../../common/AuraDownload';
import ChooseSupplier from '../order/Dialogs/ChooseSupplier';
import CreateSupplierOrder from '../order/Dialogs/SupplierShipping';
import getFilterOptions from './filterOptions';
import ProductDialog from '../product/dialogs/ProductDialog';


function Orders(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { pathname } = location
  const isSupplierInvoice = pathname.split('/').includes('supplier-invoices')
  const hasPermission = user && FuseUtils.hasPermission(authPermissions.manage, user.role);
  const options = getFilterOptions(props.type, user.role, isSupplierInvoice);
  const [filterType, setSelectedFilterType] = useState(options[0])
  const ref = useRef()
  const hasFilter = useSelector(state => state.eCommerceApp.orders.params)

  useEffect(() => {
    if (location.search) {
      const sp = new URLSearchParams(location.search);
      switch (props.type) {
        case 'order':
          if (sp.has('include_invoice_status'))
            setSelectedFilterType(sp.get('include_invoice_status') === 'true')
          break;
        case 'invoice':
          if (sp.has('cid') && sp.has('archive'))
            setSelectedFilterType(options[2])
          else if (sp.has('sid') && sp.has('archive'))
            setSelectedFilterType(options[3])
          else if (sp.has('cid'))
            setSelectedFilterType(options[0])
          else if (sp.has('sid'))
            setSelectedFilterType(options[1])
          break;

        case 'rfq':
        case 'quote':
        case 'rfd':
        case 'completed':
          if (sp.has('archive'))
            setSelectedFilterType(options[1])
          break;

        default:
          break;
      }
    }
  }, [location])

  useEffect(() => {
    if (!location.search) {
      //  ToDo need to check why this condition was written

      // if (isSupplierInvoice) {
      //   setSelectedFilterType(options[1])
      // }
      // else {
      //   setSelectedFilterType(options[0])
      // }
      setSelectedFilterType(options[0])
      dispatch(setCustomer(null));

    }
  }, [props.type, isSupplierInvoice])

  function handleShow(id, isManage, customer, listType, role) {
    switch (id) {
      case 1:
        return isManage;
      case 2:
      case 3:
        return isManage && ['Orders', 'Quotes'].includes(listType);
      case 4:
        return isManage && customer != null;
      case 5:
        return isCustomer(role) && !['Quotes'].includes(listType);
      case 6:
        return isManage && ['Orders', 'Quotes', 'Rfqs'].includes(listType);
      case 7:
        return (isSupplier(role) && listType === 'Orders') || (isManage && ['Orders', 'Quotes', 'Rfqs'].includes(listType));
      case 8:
      case 9:
        return isManage && listType === 'Orders';

      default:
        return false;
    }
  }
  function getTableColumns(isManage, customer, role) {
    let selection = false;
    if (customer) {
      selection = true;
    }
    return getColumns(isManage, props.type, selection, role, filterType?.value, isSupplierInvoice, user);
  }


  const getType = () => {
    let title = ''
    switch (props.type) {
      case 'approval':
        title = 'Waiting for Approval'
        return title;
      case 'rfq':
        title = `${upperCase(props.type)}S`
        return title;
      case 'completed':
        title = `RFD ${props.type.charAt(0).toUpperCase() + props.type.slice(1)}`
        return title
      case 'rfd':
        title = 'RFPS'
        return title
      case 'confirmed':
        title = 'Confirmed Orders'
        return title
      case 'order':
        if (isDefaultSupplierTypes(user)) {
          title = 'Received Orders'
          return title
        }
        return 'Orders'


      default:
        title = `${_.capitalize(props.type)}s`
        return title;
    }
  }


  const type = isSupplierInvoice ? 'Supplier Invoices' : getType();
  console.log('type', type)
  let headerTitle = type
  if (type === 'RFQS') headerTitle = "Request for Quote"
  if (['completed'].includes(props.type)) headerTitle = "RFP Completed"
  if (['fulfilled'].includes(props.type)) headerTitle = "Shipped Orders"
  if (['in-production'].includes(props.type))
    headerTitle = _.startCase(props.type)
  if (['container'].includes(props.type))
    headerTitle = 'Container Shipments'

  const dialog = {
    required: true,
    component: OrderDialog,
    additional: {
      required: true,
      component: ViewOrderDialog,
      data: {
        isDashboard: false,
      },
    },
  };


  const filterComponent = filterProps => {
    const sendProps = { ...filterProps, filterValue: filterType, setSupplier, setSelectedFilterType }
    return (<div className='flex'>
      {((['order', 'rfq', 'quote', 'rfd', 'completed', 'invoice'].includes(props.type) && hasPermission) || isCustomer(user.role) && ['order', 'invoice'].includes(props.type)) ? <OrderTypeFilter {...sendProps} options={options} role={user.role} type={props.type} isSupplierInvoice={isSupplierInvoice} /> : null}
      {props.type === 'order' && hasPermission && ['customer', 'customer_archive'].includes(filterType?.value) ? <OrderTyepFilterFunction /> : null}
      {!isCustomer(user.role) && (
        <>

          {!['shipment', 'container'].includes(props.type) && <CustomerFilter {...sendProps} isOrderModule pageRef={ref} />}

        </>
      )}
      {
        props.type === 'order' && !isCustomer(user.role) && (
          <>
            <IncludeInvoiceFilter {...sendProps} />
          </>
        )
      }
    </div>)
  }

  const getMessage = () => {
    const param = { ...hasFilter }
    delete param.stage
    let message = 'Are you sure you want to export all the record(s)'
    if (Object.keys(param).length < 1 || Object.values(param).every(value => value === '')) {
      return message;
    }
    message = `${message} in the current view?`
    return message;
  }

  const headerData = {
    headerTitle,
    search: {
      enabled: true,
    },
    filter: {
      component: filterComponent,
    },
    updateShow: true,
    statusCategory: `${props.type === 'approval' ? 'proof' : props.type}_status`,
    links: !['invoice', 'approval', 'container'].includes(props.type) ? [
      {
        id: 1,
        title: `New ${props.type}`,
        type: 'new',
        value: '',
        link: type === 'RFQS' ? 'request/new?type=request' : `/${props.type}s/new`,
        show: false,
      },
      {
        id: 2,
        title: `Import Multiple ${type}`,
        type: 'import',
        value: props.type,
        link: 'N/A',
        show: false,
        stage: props.type
      },
      {
        id: 6,
        title: `Import Single ${_.capitalize(props.type)}`,
        type: 'import',
        params: { single: true },
        value: props.type,
        link: 'N/A',
        show: false,
        icon: 'download'
      },
      {
        id: 3,
        title: `Import Archive ${type}`,
        type: 'import',
        value: props.type,
        params: { archive: 'yes' },
        link: 'N/A',
        icon: 'archive',
        show: false,
      },
      {
        id: 4,
        title: 'Customers',
        type: 'back',
        value: `${props.type}s`,
        link: '/customers',
        show: false,
      },
      {
        id: 5,
        title: `New ${props.type === 'order' ? 'Order' : 'RFQ'}`,
        type: 'new',
        value: '',
        link: type === 'RFQS' ? '/rfqs/request/new?type=request' : '/products',
        show: false,
      },
      {
        id: 7,
        type: 'export',
        title: `Export ${props.type}s`,
        onClick: () => {
          dispatch(
            openDialog({
              children: (
                <Confirm title={`Export ${props.type}s`}
                  content={getMessage()}
                  onSubmit={() => {
                    downloadFile({ params: { export: true, stage: props.type }, endPoint: '/api/orders' })
                      .then(() => dispatch(showMessage({ type: 'success', message: 'File downloaded successfully' })))
                      .catch(() => dispatch(showMessage({ type: 'error', message: 'Failed to download' })))
                  }}
                />
              )
            }))
        }
      },
      {
        id: 8,
        type: 'sample_file',
        title: 'Download single order import excel format',
        path: '/sampleFiles/order/single_order_import_excel_format.xlsx'
      },

      {
        id: 9,
        type: 'sample_file',
        title: ' Download single order with supplier price import excel format',
        path: '/sampleFiles/order/single_order_import_with_supplier_price_excel_format.xlsx'
      },
    ] : [],
  };



  return (
    <>
      <Entities
        headerData={headerData}
        entitiesSelector="orders"
        type={props.type}
        listType={type}
        statusCategory={headerData.statusCategory}
        getEntities={getOrders}
        getEntityStatuses={getOrderStatuses}
        selectEntities={selectOrders}
        setCustomer={setCustomer}
        setEntitiesSearchText={setOrdersSearchText}
        dialog={dialog}
        handleShow={handleShow}
        getColumns={getTableColumns}
        uniqueName={`${props.type}Table`}
        filterType={filterType}
      />
      <ReallocationDialog />
      <ChooseSupplier />
      <CreateSupplierOrder />
      <ProductDialog />
    </>
  );
}
export default Orders;
